import Resizer from 'react-image-file-resizer';

const fileTob64 = (file) => {
  let promise;
  // console.log({f: file.type})
  if (file.type.includes('image') && file.type !== 'image/tiff') {
    promise = new Promise((resolve, reject) => {
      Resizer.imageFileResizer(
        file,
        1280,
        720,
        'JPEG',
        30,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });
    // Resizer.imageFileResizer(
    //   file, // Is the file of the image which will resized.
    //   maxWidth, // Is the maxWidth of the resized new image.
    //   maxHeight, // Is the maxHeight of the resized new image.
    //   compressFormat, // Is the compressFormat of the resized new image.
    //   quality, // Is the quality of the resized new image.
    //   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
    //   responseUriFunc, // Is the callBack function of the resized new image URI.
    //   outputType, // Is the output type of the resized new image.
    //   minWidth, // Is the minWidth of the resized new image.
    //   minHeight // Is the minHeight of the resized new image.
    // );
  } else {
    promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (!!reader.result) {
          resolve(reader.result);
        } else {
          reject(Error('Failed converting to base64'));
        }
      };
    });
  }
  return promise;
};

export default fileTob64;
